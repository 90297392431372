<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Pending cart</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Search by name, email"
                      v-model="search.info"
                      v-on:keyup.enter="searchPendingCart"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                      v-on:keyup.enter="searchPendingCart()">
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" :md="currentUser.access_type!='score' ? 4 : 8">
                  <v-btn
                      @click.prevent="searchPendingCart"
                      class="btn btn-primary float-right"
                      dark
                      :loading="isLoading">
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>
              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Date</th>
                  <th class="px-3">Enroller name</th>
                  <th class="px-3">Enroller email</th>
                  <th class="px-3">Amount</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="pending_carts.length > 0" v-for="(item, index) in pending_carts" :key="index">
                    <td class="width">
                      {{item.cart_created_at}}
                    </td>
                    <td>
                      <a @click.prevent="openQuickView(item.user_id)" class="mb-1">
                        {{item.user_full_name }}
                      </a>
                    </td>

                    <td>
                      <a class="text-info mb-1" :href="'mailto:'+item.user_email">
                        {{item.user_email ? item.user_email : 'NA' }}
                      </a><br/>

                    </td>
                    <td class="px-3">
                      <span class="mb-1">
                        {{item.currency_symbol ? item.currency_symbol : '$' }} {{item.amount_total }}
                      </span>
                    </td>
                    <td>
                      <span class="mb-1" >
                        {{item.score_prefix }}
                      </span>
                    </td>
                    <td class="px-3">

                      <span v-html="item.order_status_text"></span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link"
                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                    no-caret right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="loginAsAccountHolder(item.user_id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-user-lock"></i>
                                </span>
                                <span class="navi-text">Log in as enroller</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" >
                              <a class="navi-link" @click="completeCart(item)">
                                <span class="navi-icon">
                                    <v-icon color="darken-2">fas fa-file-invoice</v-icon>
                                </span>
                                <span class="navi-text">Finalise enrolment</span>
                              </a>
                            </b-dropdown-text>



                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>

                      </template>
                    </td>
                  </tr>
                  <tr v-if="pending_carts.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No data found</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="pending_carts.length > 0"
                  class="pull-right mt-7"
                  @input="getAllPendingCart"
                  v-model="page"
                  :total-rows="total"
                  :disabled="isLoading"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <update-cart ref="update-cart" @refresh="getAllPendingCart"></update-cart>
  </v-app>
</template>
<script>
import cartService from "@/services/cart/cartService";
import UpdateCart from "@/view/pages/view/user/general/cart/UpdateCart";
import ScoreService from "@/services/score/score/ScoreService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";

const score=new ScoreService();
const cart=new cartService();
const accountholder = new AccountholderService();

export default {
  props:['account_holder_name'],
  components: {
    UpdateCart
  },
  data(){
    return{
      search:{
        info:'',
      },
      isLoading:false,
      pending_carts:[],
      scores:[],
      total: null,
      perPage: null,
      page: null,
    }
  },
  mounted() {
    this.getAllPendingCart();
    this.getAllScore();
  },
  methods:{
    searchPendingCart(){
      this.getAllPendingCart();
    },

    loginAsAccountHolder(accountHolderId)
    {
      accountholder.loginAsAccountHolder(accountHolderId).then(response=>{
        if(response.data.status=='OK')
          window.open(response.data.url,'_blank')
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    getAllPendingCart(){

      cart
          .getPendingCart(this.search,this.page)
          .then(response => {
            this.pending_carts=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch(() => {

          })
          .finally(() =>
              this.isLoading = false
          )
    },
    completeCart(cart){
      this.$refs['update-cart'].updateCart(cart,cart.user_id,cart.user_full_name,cart.user_score_id);
    },
    openQuickView(itemId){

      this.$router.push({
        name: "accountholder-edit",
        params: { accountholderId: itemId }
      });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
}
</script>